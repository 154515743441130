import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import tw, { styled } from 'twin.macro'

import {Layout} from '@components/layout/layout'
import { ListDisc, MainContainer, RightContainer } from "@components/oferta.style"
import { H1, H3, Paragph } from "@components/layout/style"

const Grid = styled('div')`
${tw`grid grid-flow-col grid-cols-2 auto-rows-auto gap-4 leading-normal`}
`

const RodoTemplate:React.FC = ({data}) => (<Layout data={data}
  prop={{
    canonicalUrl: 'https://www.mediroot.pl/rodo/',
    title: 'Rodo - usługi stomatologiczne | Mediroot',
    description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
  <StaticImage
    src="../assets/images/hero.jpg"
    placeholder="blurred"
    alt="Hero image"
    layout="fullWidth" />

  <MainContainer>
    <RightContainer>
      <H1>
      Klauzula informacyjna dotycząca pacjentów
      </H1>

      <Paragph>
      Drodzy Pacjenci, w związku z realizacją wymogów Rozporządzenia Parlamentu Europejskiego i Rady UE
2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy
95/49/WE (ogólne rozporządzenie o ochronie danych „RODO”), Administratorem Państwa danych
osobowych jest Mediroot Urszula Korzeniowska, z siedzibą przy
ul. św. Wojciecha 16, 62-100 Wągrowiec, NIP 8431611830 zwany dalej: „Administratorem”.
      </Paragph>

      <Paragph>
      Możesz skontaktować się z Administratorem dzwoniąc pod numer telefonu 61 306 71 01 lub pisząc na
adres mailowy: mediroot.wagrowiec@gmail.com lub korespondencyjny: ul. św. Wojciecha 16, 62-100 Wągrowiec.
      </Paragph>

      <Paragph>
      Państwa dane przetwarzane są w celu ochrony stanu zdrowia, świadczenia usług medycznych,
zarządzania udzielaniem świadczeń zdrowotnych, kontaktu z Państwem jako naszym Pacjentem,
jak również w celu wydania dokumentacji medycznej osobom przez Państwa upoważnionym.
Dane przetwarzamy także w związku z utrzymaniem i zapewnieniem bezpieczeństwa systemu
teleinformatycznego, w którym przetwarzana jest dokumentacja medyczna. Podstawą prawną
przetwarzania pozyskanych danych jest Ustawa z dnia 16 listopada 2008r. o prawach pacjenta
i Rzeczniku Praw Pacjenta. Dodatkowo, przepisy prawa wymagają od Administratora przetwarzania
danych w celach statystycznych, rachunkowych czy podatkowych.
      </Paragph>

      <Paragph>
      Państwa dane przetwarzane są wyłącznie w zakresie związanym z realizacją powyższych celów.
Dane mogą zostać ujawnione osobom upoważnionym przez Administratora np. lekarzom będącymi
pracownikami i współpracownikami, innym podmiotom leczniczym w celu zapewnienia ciągłości
leczenia oraz dostępności świadczeń zdrowotnych, a także innym podmiotom działającym w naszym
imieniu tj. laboratoria techniczne, dostawca oprogramowania elektronicznej dokumentacji medycznej,
informatycy świadczący usługi serwisowe na podstawie klauzuli powierzenia danych.
Nie udostępniamy Państwa danych innym odbiorcom oprócz podmiotów upoważnionych na podstawie
przepisów prawa.
      </Paragph>

      <Paragph>
      Obowiązek podania danych wynika z właściwych przepisów prawa, w tym ustawy z dnia 6 listopada
2008 r. o prawach pacjenta i Rzeczniku Praw Pacjenta oraz ustawy z dnia 27 sierpnia 2004 r.
o świadczeniach opieki zdrowotnej finansowanych ze środków publicznych.
Podanie przez Państwa danych osobowych jest obowiązkowe na podstawie przepisów prawa,
a konsekwencją niepodania danych osobowych będzie brak możliwości załatwienia danej sprawy.
      </Paragph>

      <Paragph>
      Administrator nie zamierza przekazywać Państwa danych do państwa trzeciego ani do organizacji
międzynarodowych.
      </Paragph>

      <Paragph>
      Państwa dane będą przechowywane nie dłużej niż jest to konieczne, tj. przez okres jaki przewiduje
Ustawa z dnia 6 listopada 20018r. o prawach pacjenta i Rzeczniku Praw Pacjenta.
      </Paragph>

      <Paragph>
      Pacjenci mają prawo dostępu do treści swoich danych oraz ich sprostowania, prawo do przenoszenia
danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie jak również
ograniczenia przetwarzania danych zgodnie z określonymi zasadami udostępniania dokumentacji
medycznej przez przepisy polskiego prawa.
      </Paragph>

      <Paragph>
      W związku z przetwarzaniem danych osobowych przez Administratora przysługuje prawo wniesienia
skargi do UODO, gdy uznają Państwo, iż przetwarzanie danych osobowych narusza przepisy ogólnego
rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r
      </Paragph>

      <Paragph>
      Podanie przez Państwa danych osobowych jest obowiązkowe na podstawie przepisów prawa,
a konsekwencją ich niepodania jest brak możliwości świadczenia usług przez Administratora.
      </Paragph>

      <Paragph>
      W oparciu o podane dane osobowe Administrator nie będzie podejmował wobec Państwa
zautomatyzowanych decyzji będących wynikiem profilowania.
      </Paragph>
    </RightContainer>
  </MainContainer>
</Layout>);

export default RodoTemplate;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
